import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { withUserContext } from "../../../../context/UserContext"
import { isBrowser } from "../../../../context/ApolloContext"
import { useLocation } from "@reach/router"
import {
  handelLangRedirects,
  handleCurrRedirects,
} from "../../../../utils/langCurrSeclectorHelper"
import DropDownOurExpertise from "../DropDownOurExpertise"
import EmailIcon from "../../../../../static/email.png"
import LyneUpIcon from "../../../../../static/LyneUP.png"
import LyneUpFemaleIcon from "../../../../../static/LyneUPFemale.jpg"
import LyneFitIcon from "../../../../../static/LyneFIT.png"
import LyneHomeIcon from "../../../../../static/LyneHOME.png"
import LoopIcon from "../../../../../static/Loop.jpg"
import ChristmasGiftCard from "../../../../../static/ChristmasGiftCard.jpg"
import ShopIcon from "../../../../../static/Shop.jpg"
import B2bIcon from "../../../../../static/B2B.jpg"
import OffresIcon from "../../../../../static/Offres.jpg"
import NoelOffreIcon from "../../../../../static/NoelOffre.jpg"
import MatelasIcon from "../../../../../static/Matelas.jpg"
import SurMatelasIcon from "../../../../../static/Surmatelas.jpg"
import OreillerIcon from "../../../../../static/Oreiller.jpg"
import MedicaleIcon from "../../../../../static/Medicale.jpg"
import AboutPerckoIcon from "../../../../../static/AboutPercko.jpg"
import BackHealthIcon from "../../../../../static/BackHealth.jpg"
import TheyLoveUsIcon from "../../../../../static/TheyLoveUs.jpg"
import SommierIcon from "../../../../../static/SommierIcon.jpg"
import ShowroomIcon from "../../../../../static/ShowroomIcon.jpg"
import ChaiseIcon from "../../../../../static/ChaiseIcon.jpg"
import TshirtsIcon from "../../../../../static/T-shirts.jpg"
import { FaAngleRight, FaAngleDown } from "react-icons/fa"
import {
  dropCurrencyOptions,
  dropLangOptions,
} from "../../../../utils/langDropHelper"
import theme from "../../../../utils/theme"

const currencies = dropCurrencyOptions()
const languages = dropLangOptions()

const MobileMenuComp = ({
  openCloseMenu,
  exclusifPromo,
  intl,
  userContext,
}) => {
  const [DropOpen, setDropOpen] = useState({})
  const [langDropOpen, setlangDropOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const toggleModal = () => setIsOpen(!isOpen)
  const { profile, handleLogout, handleLogin, handleSignup } = userContext
  const [loginOpen, setloginOpen] = useState(false)
  const isLoggedIn = Object.keys(profile).length !== 0
  const [CurrDropOpen, setCurrDropOpen] = useState(false)
  const [userLogInError, setuserLogInError] = useState("")

  let DashboardUrl = intl.locale === "fr" ? "/mon-compte/" : "/dashboard/"

  let OrdersUrl = intl.locale === "fr" ? "commande/" : "orders/"

  let EditAddressUrl = intl.locale === "fr" ? "adresses/" : "edit-address/"

  let PaymentMethodsUrl =
    intl.locale === "fr" ? "moyens-de-paiement/" : "payment-methods/"

  let EditAccountUrl =
    intl.locale === "fr" ? "details-du-compte/" : "edit-account/"

  const mobileNavBarConfig = intl => {
    return [
      {
        intl_id: exclusifPromo
          ? "menu_our_product_offres_exclusif"
          : process.env &&
            (process.env.GATSBY_ACTIVE_ENV === "eur" ||
              process.env.GATSBY_ACTIVE_ENV === "general") &&
            intl.locale === "fr"
          ? "mobile_menu_offres"
          : null,
        dropdown: null,
        link:
          exclusifPromo === "exclu"
            ? "/shop/offres-enplus/?discount_code=exclu"
            : exclusifPromo === "exclusif"
            ? "/shop/offres-exclusives/?discount_code=exclusif"
            : "/shop/offres/",
        image: exclusifPromo ? OffresIcon : NoelOffreIcon,
      },
      {
        intl_id:
          !exclusifPromo &&
          process.env &&
          (process.env.GATSBY_ACTIVE_ENV === "eur" ||
            process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr"
            ? "mobile_menu_showroom"
            : null,
        dropdown: null,
        link: "/showroom/",
        image: ShowroomIcon,
      },
      {
        intl_id:
          !exclusifPromo &&
          process.env &&
          (process.env.GATSBY_ACTIVE_ENV === "eur" ||
            process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr"
            ? "mobile_menu_chaise"
            : exclusifPromo === "exclusif"
            ? "mobile_menu_chaise"
            : null,
        dropdown: null,
        link:
          exclusifPromo === "exclusif"
            ? "/shop/chaise-bureau-ergonomique-exclusif/"
            : "/shop/chaise-bureau-ergonomique/",
        image: ChaiseIcon,
      },
      {
        intl_id:
          (process.env.GATSBY_ACTIVE_ENV === "eur" ||
            process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr"
            ? null
            : "mobile_menu_lyneup",
        dropdown: null,
        link:
          intl.locale === "fr"
            ? "/shop/lyneup-test/"
            : intl.locale === "en"
            ? "/shop/lyneup-test/"
            : "/shop/lyneup-2b/",
        image: LyneUpIcon,
      },
      {
        intl_id:
          (process.env.GATSBY_ACTIVE_ENV === "eur" ||
            process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr" &&
          exclusifPromo
            ? "menu_our_product_lyneup"
            : null,
        dropdown: null,
        link:
          exclusifPromo === "exclu"
            ? "/shop/lyneup-enplus/?discount_code=exclu"
            : "/shop/solutions-textiles-exclusif/?discount_code=exclusif&Gender=Female&Type=Sous-vetement",
        image: LyneUpFemaleIcon,
      },
      {
        intl_id:
          (process.env.GATSBY_ACTIVE_ENV === "eur" ||
            process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr" &&
          !exclusifPromo
            ? "mobile_menu_solution_lyne"
            : null,
        dropdown: null,
        link: "/shop/solutions-textiles/",
        image: TshirtsIcon,
      },
      {
        intl_id:
          process.env &&
          (process.env.GATSBY_ACTIVE_ENV === "eur" ||
            process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr"
            ? "mobile_menu_matelas"
            : null,
        dropdown: null,
        link:
          exclusifPromo === "exclu"
            ? "/shop/matelas-enplus/?discount_code=exclu"
            : exclusifPromo === "exclusif"
            ? "/shop/matelas-exclusif/?discount_code=exclusif"
            : "/shop/matelas/",
        image: MatelasIcon,
      },
      {
        intl_id:
          process.env &&
          (process.env.GATSBY_ACTIVE_ENV === "eur" ||
            process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr"
            ? "mobile_menu_sommier"
            : null,
        dropdown: null,
        link:
          exclusifPromo === "exclu"
            ? "/shop/sommiers-zones-enplus/?discount_code=exclu"
            : exclusifPromo === "exclusif"
            ? "/shop/sommiers-zones-exclusifs/?discount_code=exclusif"
            : "/shop/sommiers-zones/",
        image: SommierIcon,
      },
      {
        intl_id:
          exclusifPromo && exclusifPromo == "exclusif"
            ? "mobile_menu_surmatelas"
            : !exclusifPromo &&
              process.env &&
              (process.env.GATSBY_ACTIVE_ENV === "eur" ||
                process.env.GATSBY_ACTIVE_ENV === "general") &&
              intl.locale === "fr"
            ? "mobile_menu_surmatelas"
            : null,
        dropdown: null,
        link:
          exclusifPromo === "exclusif"
            ? "/shop/surmatelas-exclusif/?discount_code=exclusif"
            : "/shop/surmatelas/",
        image: SurMatelasIcon,
      },
      {
        intl_id:
          exclusifPromo && exclusifPromo == "exclusif"
            ? "mobile_menu_oreiller"
            : !exclusifPromo &&
              process.env &&
              (process.env.GATSBY_ACTIVE_ENV === "eur" ||
                process.env.GATSBY_ACTIVE_ENV === "general") &&
              intl.locale === "fr"
            ? "mobile_menu_oreiller"
            : null,
        dropdown: null,
        link:
          exclusifPromo === "exclusif"
            ? "/shop/oreillers-ergonomiques-exclusif/?discount_code=exclusif"
            : "/shop/oreillers-ergonomiques/",
        image: OreillerIcon,
      },
      {
        intl_id:
          (process.env.GATSBY_ACTIVE_ENV === "eur" ||
            process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr" &&
          !exclusifPromo
            ? null
            : exclusifPromo
            ? null
            : "mobile_menu_lynehome",
        dropdown: null,
        link: "/shop/lynehome/",
        image: LyneHomeIcon,
      },
      {
        intl_id:
          (process.env.GATSBY_ACTIVE_ENV === "eur" ||
            process.env.GATSBY_ACTIVE_ENV === "general") &&
          intl.locale === "fr" &&
          !exclusifPromo
            ? null
            : exclusifPromo
            ? null
            : "mobile_menu_lynefit",
        dropdown: null,
        link: "/shop/lynefit/",
        image: LyneFitIcon,
      },
      {
        intl_id: exclusifPromo
          ? null
          : intl.locale === "fr" || intl.locale === "en"
          ? "mobile_menu_loop"
          : null,
        dropdown: null,
        link: "/shop/loop/",
        image: LoopIcon,
      },
      {
        intl_id:
          !exclusifPromo &&
          process.env &&
          process.env.GATSBY_ACTIVE_ENV === "eur" &&
          intl.locale === "fr"
            ? "mobile_menu_gift_card"
            : null,
        dropdown: null,
        link: `${intl.locale === "fr" ? "/e-carte-cadeau/" : "/gifts/"}`,
        image: ChristmasGiftCard,
      },
      {
        intl_id: exclusifPromo ? null : "mobile_menu_our_products",
        dropdown: null,
        link: "/shop/",
        image: ShopIcon,
      },
      {
        intl_id: exclusifPromo ? null : "mobile_menu_research",
        dropdown: null,
        link:
          intl.locale === "fr" ? "/dispositif-medical/" : "/scientific-study/",
        image: MedicaleIcon,
      },
      {
        intl_id: exclusifPromo
          ? null
          : intl.locale === "en"
          ? "mobile_menu_back_pain"
          : "mobile_menu_our_expertise",
        dropdown: DropDownOurExpertise,
        link: `https://${process.env.GATSBY_WEBSITE_URL}/${process.env.GATSBY_ACTIVE_ENV}/`,
        image: BackHealthIcon,
      },
      {
        intl_id: exclusifPromo
          ? null
          : process.env &&
            (process.env.GATSBY_ACTIVE_ENV === "eur" ||
              process.env.GATSBY_ACTIVE_ENV === "general") &&
            intl.locale === "fr"
          ? "mobile_menu_business_solution"
          : null,
        dropdown: null,
        link: `${
          intl.locale === "fr"
            ? "https://pro.percko.com/"
            : "https://anchorpercko.com/"
        }`,
        image: B2bIcon,
      },
      {
        intl_id: exclusifPromo
          ? null
          : process.env &&
            (process.env.GATSBY_ACTIVE_ENV === "gbp" ||
              process.env.GATSBY_ACTIVE_ENV === "general") &&
            intl.locale === "en"
          ? "mobile_menu_our_story"
          : null,
        dropdown: null,
        link: `${intl.locale === "fr" ? "/notre-histoire/" : "/our-story/"}`,
        image: AboutPerckoIcon,
      },
      {
        intl_id: exclusifPromo
          ? null
          : process.env &&
            (process.env.GATSBY_ACTIVE_ENV === "gbp" ||
              process.env.GATSBY_ACTIVE_ENV === "general") &&
            intl.locale === "en"
          ? "mobile_menu_they_love_us"
          : null,
        dropdown: null,
        link: `${
          intl.locale === "fr" ? "/ils-nous-aiment/" : "/they-love-us/"
        }`,
        image: TheyLoveUsIcon,
      },
    ]
  }

  const handleLoginAction = (username, password) => {
    // handleLogin(username, password, d)
    if (!username) {
      setuserLogInError(intl.formatMessage({ id: "empty__email" }))
    } else if (!password) {
      setuserLogInError(intl.formatMessage({ id: "empty__password" }))
    } else {
      handleLogin(username, password, d => {
        if (d.data.customerAccessTokenCreate.customerUserErrors.length === 0) {
          toggleModal()
        } else {
          d.data.customerAccessTokenCreate.customerUserErrors.map(item => {
            if (item.code === "TAKEN") {
              setuserLogInError(intl.formatMessage({ id: "email_taken" }))
            } else if (item.code === "TOO_SHORT") {
              setuserLogInError(intl.formatMessage({ id: "pass_too_short" }))
            } else if (item.code === "UNIDENTIFIED_CUSTOMER") {
              setuserLogInError(
                intl.formatMessage({ id: "unidentified_customer" })
              )
            } else {
              setuserLogInError(intl.formatMessage({ id: "email_invalid" }))
            }
          })
        }
      })
    }
    // toggleModal()
  }

  const { pathname, search, origin } = useLocation()

  const CurrencyDropDown = () => {
    const locale = process.env.GATSBY_CURRENCY_TYPE || "eur"
    const lang = currencies.find(lang => lang.curr === locale)

    const handleOnClickCurr = curr => {
      if (isBrowser) {
        let newLocation = handleCurrRedirects({
          newCurr: curr,
          pathname,
          origin,
          search,
        })
        location = newLocation
      }
    }
    return (
      <CurrencyDropDownWrapper>
        <MobileCurrencyBox
          arrow={true}
          onClick={() => {
            setCurrDropOpen(!CurrDropOpen)
            setlangDropOpen(false)
          }}
          open={CurrDropOpen}
        >
          <span>{lang.title}</span>
        </MobileCurrencyBox>
        <MobileCurrencySelector>
          {CurrDropOpen &&
            currencies.map(
              (lang, index) =>
                lang.lang.includes(intl.locale) && (
                  <a
                    onClick={() => {
                      handleOnClickCurr(lang.curr)
                      if (isBrowser) {
                        localStorage.setItem("favCurr", curr || "eur")
                        sessionStorage.setItem(
                          "selectedCountry",
                          lang.curr || "eur"
                        )
                      }
                    }}
                    key={index.toString()}
                  >
                    <div
                      style={{
                        margin: "10px 0px ",
                        fontSize: 14,
                      }}
                    >
                      <span>{lang.title}</span>
                    </div>
                  </a>
                )
            )}
        </MobileCurrencySelector>
      </CurrencyDropDownWrapper>
    )
  }

  const LanguageDropDown = () => {
    const locale = process.env.GATSBY_CURRENCY_TYPE || "eur"
    const lang = languages.find(lang => lang.locale === intl.locale)
    return (
      <LanguageDropDownWrapper>
        <MobileLanguageBox
          arrow={true}
          onClick={() => {
            setlangDropOpen(!langDropOpen)
            setCurrDropOpen(false)
          }}
          open={langDropOpen}
        >
          <img
            style={{
              marginBottom: 0,
              width: 30,
            }}
            className="lazyload"
            data-src={lang.flag}
            alt={lang.alt}
            title={lang.title}
          />
        </MobileLanguageBox>
        <MobileLanguageSelector>
          {langDropOpen &&
            languages.map((lang, index) => (
              <div
                key={index.toString()}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "10px 0px",
                  alignItems: "center",
                }}
                onClick={() => handleOnClickLangChange(lang.locale)}
              >
                <img
                  className="lazyload"
                  data-src={lang.flag}
                  alt={lang.alt}
                  title={lang.title}
                />
                <span>{lang.title}</span>
              </div>
            ))}
        </MobileLanguageSelector>
      </LanguageDropDownWrapper>
    )
  }

  useEffect(() => {
    let obj = { ...DropOpen }
    for (let i = 0; i < mobileNavBarConfig(intl).length; i++) {
      obj[i] = false
    }
    setDropOpen(obj)
  }, [])

  const handleOnClick = (i, isdropdown) => {
    let arr = { ...DropOpen }
    arr[i] = !DropOpen[i] && isdropdown
    setDropOpen(arr)
  }

  const lang = languages.find(lang => lang.locale === intl.locale)

  const handleOnClickLangChange = locale => {
    if (isBrowser) {
      let newLocation = handelLangRedirects({
        locale: locale,
        origin: origin,
        pathname: pathname,
        search: search,
      })
      location = newLocation
    }
  }

  return (
    <Wrapper>
      {/* <MenuCloseButton onClick={() => openCloseMenu(false)}>×</MenuCloseButton> */}
      {mobileNavBarConfig(intl).map(
        (item, index) =>
          item.intl_id && (
            <ChildList
              onClick={() => {
                setlangDropOpen(false)
                setCurrDropOpen(false)
              }}
              open={DropOpen[index]}
              key={index.toString()}
              arrow={!!item.dropdown}
            >
              {!!item.dropdown && DropOpen[index] ? (
                <DropDownIcon
                  onClick={() => handleOnClick(index, !!item.dropdown)}
                >
                  <FaAngleDown
                    style={{ cursor: "pointer" }}
                    color="#262626"
                    size={24}
                  />
                </DropDownIcon>
              ) : !!item.dropdown ? (
                <DropDownIcon
                  onClick={() => handleOnClick(index, !!item.dropdown)}
                >
                  <FaAngleRight
                    style={{ cursor: "pointer" }}
                    color="262626"
                    size={24}
                  />
                </DropDownIcon>
              ) : (
                ""
              )}
              {item.link && item.link.indexOf("http") == 0 ? (
                <a target="blank" href={item.link} rel="noopener">
                  <LinkImage className="lazyload" data-src={item.image} />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: item.intl_id }),
                    }}
                  />
                </a>
              ) : (
                <Link
                  to={item.link}
                  onClick={() => openCloseMenu(false)}
                  style={{
                    color:
                      exclusifPromo === "exclu" && index === 0
                        ? theme.colors.primary.enplus
                        : exclusifPromo === "exclusif" && index === 0
                        ? theme.colors.primary.exclusif
                        : index === 0
                        ? theme.saleColor.menuColor
                        : "#262626",
                  }}
                >
                  <LinkImage className="lazyload" data-src={item.image} />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: item.intl_id }),
                    }}
                  />
                </Link>
              )}
              {DropOpen[index] && (
                <item.dropdown
                  mobile={true}
                  onMouseLeave={() => openCloseMenu(false)}
                />
              )}
            </ChildList>
          )
      )}
      <MobileMenuBottom>
        <EmailBox to="/contact/" onClick={() => openCloseMenu(false)}>
          <img className="lazyload" data-src={EmailIcon} />
        </EmailBox>
        {!exclusifPromo && <CurrencyDropDown />}
        {!exclusifPromo && <LanguageDropDown />}
      </MobileMenuBottom>
    </Wrapper>
  )
}

const MenuCloseButton = styled.button`
  background: #262626;
  border: 0px;
  cursor: pointer;
  outline: none;
  z-index: 9;
  box-shadow: none;
  position: fixed;
  top: 0px;
  right: 0;
  text-align: center;
  padding: 0;
  margin: 0 !important;
  color: #fff;
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  min-height: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-family: Arial, monospace;
  transform: translateY(0) translateZ(1px);

  :hover {
    color: #fff;
  }
`

const Wrapper = styled.div`
  display: flex;
  padding: 10px 10px 48px;
  flex-direction: column;
  overflow: auto;
  width: 100%;
`

const ChildList = styled.div`
  border-bottom: 2px solid #ececec;
  color: #262626;
  font-size: 16px;
  line-height: 24px;
  font-family: Gotham Book;
  position: relative;
  cursor: pointer;

  a {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px 5px;
  }

  sup {
    font-style: italic;
  }
`

const IconWrapper = styled.div`
  padding: 15px 15px 15px 20px;
  display: flex;
  flex-direction: row;
  > a {
    border: 2px solid #d3d3d3;
    border-radius: 999px;
    display: flex;
    margin-right: 5px;
    /* margin-top: 11px; */
    height: 29px;
    width: 29px;
    justify-content: center;
    align-items: center;
    > svg {
      fill: #d3d3d3;
    }
    :hover {
      :first-of-type {
        border: 2px solid #3b5998;
        background: #3b5998;
      }
      border: 2px solid #0072b1;
      background: #0072b1;
      > svg {
        fill: white;
      }
    }
    transition: all 0.3s linear;
  }
`

const MobileMenuBottom = styled.div`
  display: flex;
  padding: 12px 15px;
  background: #262626;
  position: absolute;
  left: 0px;
  width: 100%;
  bottom: 0px;
  justify-content: space-between;
`

const EmailBox = styled(Link)`
  display: flex;
  align-items: center;

  img {
    width: 30px;
    max-height: 20px;
    margin: 0px auto;
  }
`

const LanguageDropDownWrapper = styled.div`
  display: flex;
  padding: 0px 0px 0px 10px;
  position: relative;
  justify-content: space-between;
  align-items: center;
`

const MobileLanguageBox = styled.div`
  padding: 0px 25px 0px 0px;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-family: "Gotham Book";
  position: relative;
  cursor: pointer;
  :before {
    content: "";
    border: solid #fff;
    border-width: 0 2px 2px 0;
    padding: 5px;
    transition: transform 0.2s;
    position: absolute;
    right: 0px;
    top: ${({ open }) => (open ? "7px" : "1px")};
    transform: ${({ open }) => (open ? "rotate(-135deg)" : "rotate(45deg)")};
  }
`

const MobileLanguageSelector = styled.div`
  display: flex;
  padding: 0px 20px;
  flex-direction: column;
  background: #e6e6e6;
  position: absolute;
  right: -20px;
  color: #262626;
  bottom: 35px;
  width: 135px;

  img {
    margin: 0px 10px 0px 0px;
    width: 25px;
  }
`

const CurrencyDropDownWrapper = styled.div`
  display: flex;
  padding: 0px 5px 0px 10px;
  position: relative;
  justify-content: space-between;
  align-items: center;
`

const MobileCurrencyBox = styled.div`
  padding: 0px 25px 0px 0px;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-family: "Gotham Book";
  position: relative;
  cursor: pointer;
  :before {
    content: "";
    border: solid #fff;
    border-width: 0 2px 2px 0;
    padding: 5px;
    transition: transform 0.2s;
    position: absolute;
    right: 0px;
    top: ${({ open }) => (open ? "7px" : "1px")};
    transform: ${({ open }) => (open ? "rotate(-135deg)" : "rotate(45deg)")};
  }
`

const MobileCurrencySelector = styled.div`
  display: flex;
  padding: 0px 20px;
  flex-direction: column;
  background: #e6e6e6;
  position: absolute;
  right: 0px;
  color: #262626;
  bottom: 35px;
`

const LinkImage = styled.img`
  margin: 0px 10px 0px 0px;
  width: 45px;
  border-radius: 50%;
  overflow: hidden;
`

const DropDownIcon = styled.div`
  color: #262626;
  cursor: pointer;
  position: absolute;
  right: -2px;
  margin: 0px;
  height: 67px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export default withUserContext(injectIntl(MobileMenuComp))
